import { useRef } from 'react';
import { useClickAway } from 'ahooks';
import { create, useModal } from '@ebay/nice-modal-react';
import CrossImg from '@/CrossImg';

const Message = create(({ title, text, buttonProps = { hidden: false, text: '关闭' } }) => {
    const modal = useModal();
    const ref = useRef(null);
    useClickAway(() => {
        modal.remove();
    }, ref);

    return (
        modal.visible ? (
            <div className="overlay" onClick={(event) => {
                event.preventDefault();
            }}>
                <section ref={ref} className="popup msgBox">
                    <div className="titleBar">
                        <h3 className="">{title}</h3>
                        <button type="button" className="closeBtnMsg" onClick={() => { modal.remove(); }}>
                            <CrossImg />
                        </button>
                    </div>
                    <div className="contentBlock">
                        <p className="">{text}</p>
                        {buttonProps.hidden ? null : <button type="button" className="closeBtnBottom" onClick={() => { modal.remove(); }}>{buttonProps.text}</button>}
                    </div>
                </section>
            </div>
        ) : null
    )
})

export default Message